import React from "react"
import PropTypes, { string } from "prop-types"
import styled, { css } from "styled-components"
import "react-phone-input-2/lib/style.css"

import React2PhoneInput from "react-phone-input-2"
import Tooltip from "../Help/Tooltip"
import { CopyIcon, SearchIcon } from "../Icons/Icons"
import InputError from "./InputError"
import Label from "./Label"

const SharedInputStyle = css`
  background: white;
  border-radius: 25px;
  font-weight: 100 !important;
  padding: 10px 15px !important;
  min-width: 180px;
  width: auto;
  height: 20px;
  font-size: 15px;
  font-family: 'Museo-Sans','sans-serif';
  outline: none;
  border: 1px solid ${({ theme }) => theme.borderColor};
`

export const ThemedInput = styled.input`
  ${SharedInputStyle}
`

export const PhoneInput = ({ className, ...rest }) => <React2PhoneInput key="phoneNumber" placeholder="" className={className} {...rest} />

export const ThemedPhoneInput = styled(PhoneInput)`

  ${SharedInputStyle}

  display: inline-block;
  padding: 0 !important;
  height: 40px;

  input.form-control {
    border: none;
    background-color: transparent;
    margin-left: 60px;
    padding-left: 0px;
    display: block;
    width: calc(100% - 60px);
    height: 100%;
    font-size: 15px;
    font-family: 'Museo-Sans','sans-serif';
    font-weight: 100 !important;
  }

  .flag-dropdown {
    border-radius: 18px 0px 0px 18px;
  }

  .flag-dropdown .selected-flag {
    width: 50px;
    border-radius: 18px 0px 0px 18px;
    padding-left: 12px;
  }

  .flag-dropdown .selected-flag .arrow {
    left: 25px;
  }

  .flag-dropdown.open {
    border-radius: 18px 0px 0px 0px;
  }

  .flag-dropdown.open .selected-flag {
    border-radius: 18px 0px 0px 0px;
  }
`



const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  input[type="checkbox"] {
    accent-color: ${({ theme }) => theme.primaryColor};
    min-width: auto;
    height: 18px;
    width: 18px;
  }
  label {
    margin-bottom: 0 !important;
    margin-left: 8px;
    font-size: 15px;
  }
  ${Label} {
    flex: 1;
    label {
      margin-bottom: 0;
    }
  }
`
// export const ThemedCheckboxInput = ({ id, label, ...rest}) => {
//   return (
//     <CheckboxContainer>
//       <ThemedTextInput type="checkbox" id={id} {...rest} />
//       {label && <Label htmlFor={id}>{label}</Label>}
//     </CheckboxContainer>
//   )
// }

export const commonTextInputCss = css`
  background: white;
  border-radius: 25px;
  font-weight: ${({ $bold }) => $bold ? "bold !important" : "100 !important"};
  padding: 10px 15px !important;
  min-width: 180px;
  width: 100%;
  height: 42px;
  font-size: 15px;
  font-family: 'Museo-Sans','sans-serif';
  outline: none;
  border: 1px solid ${({ theme }) => theme.borderColor};
  box-sizing: border-box;
`

export const ThemedTextInput = styled.input`
  ${commonTextInputCss}
`


export const InputWrapper = styled.div`
  min-height: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CopiedMessage = styled.div`
  background: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.primaryContrastColor};
  border-radius: 10px;
  width: 53px;
  padding: 2px;
  text-align: center;
  top: -15px;
  right: 2px;
  position: absolute;
`

const InputPrefix = styled.span`
  position: absolute;
  left: 15px;
  font-weight: bold;
  top: 12px;
  font-size: 16px;
  color: ${({ theme }) => theme.labelDarkColor};
  opacity: 0.5;
  + input {
    padding-left: 30px !important;
  }
`

export const TextInput = ({ bold, errors = {}, name, label, prefix, submitted, tooltipId = null, tooltipContent = null, ...rest }) => (
  <>
    {label && <Label $hasToolTip={tooltipContent} htmlFor={name}>{label} {tooltipId && tooltipContent && <Tooltip id={tooltipId}>{tooltipContent}</Tooltip>}</Label>}
    <InputWrapper>
      <div style={{ display: "flex", position: "relative" }}>
        { prefix && <InputPrefix>{prefix}</InputPrefix> }
        <ThemedTextInput id={name} name={name} $bold={bold} {...rest} />
      </div>
      { errors[name] && <span><InputError>{errors[name]}</InputError></span> }
    </InputWrapper>
  </>
)

TextInput.propTypes = {
  error: PropTypes.string,
  field: PropTypes.shape({
    name: string.isRequired,
  }),
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
  tooltipContent: PropTypes.element,
  tooltipId: PropTypes.string,
  prefix: PropTypes.string,
  rest: PropTypes.any
}

export default TextInput
